/* eslint-disable react/jsx-props-no-spreading */
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import * as React from 'react';
import { createContext, useContext } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import cn from '../../utils/cn';
// Create a context to store the color prop
const AccordionColorContext = createContext<'primary' | 'secondary'>('primary');

const Accordion = ({
  children,
  color = 'primary',
  defaultValue,
  ...props
}: React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> & {
  color?: 'primary' | 'secondary';
}) => {
  return (
    <AccordionColorContext.Provider value={color}>
      <AccordionPrimitive.Root {...props}>{children}</AccordionPrimitive.Root>
    </AccordionColorContext.Provider>
  );
};

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('bg-info-container relative isolate overflow-hidden rounded-md', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  // Use the color from the context
  const color = useContext(AccordionColorContext);

  const triggerClassName =
    color === 'secondary'
      ? 'group flex flex-1 items-center justify-between gap-3 p-4 text-left text-lg font-semibold transition-all hover:bg-[#aac8ff] active:bg-[#83afff] sm:gap-5 sm:p-6 sm:text-xl [&[data-state=closed]>:nth-child(2)]:hidden [&[data-state=open]>:first-child]:hidden [&[data-state=open]]:bg-[#aac8ff]'
      : 'group flex flex-1 items-center justify-between gap-3 p-4 text-left text-lg  transition-all hover:bg-[#ddd9d3] active:bg-[#EFE9E1] sm:gap-5 sm:p-6 sm:text-xl [&[data-state=closed]>:nth-child(2)]:hidden [&[data-state=open]>:first-child]:hidden [&[data-state=open]]:bg-[#ddd9d3]';

  return (
    <AccordionPrimitive.Header className={color === 'secondary' ? 'flex' : 'flex bg-[#EFE9E1]'}>
      <AccordionPrimitive.Trigger ref={ref} className={cn(triggerClassName, className)} {...props}>
        {children}
        <FiChevronRight className="text-on-info-container h-6 w-6 shrink-0" />
        <FiChevronDown className="text-on-info-container h-6 w-6 shrink-0" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  // Use the color from the context
  const color = useContext(AccordionColorContext);

  const contentClassName = color === 'secondary' ? 'bg-[#EDF3FF]' : 'bg-[#EFE9E1]';

  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn(
        'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm transition-all',
        contentClassName,
        className,
      )}
      {...props}
    >
      <div className="p-4 pb-4 pt-0 sm:p-6">{children}</div>
    </AccordionPrimitive.Content>
  );
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
