function TableCaption({
  title,
  description,
}: {
  title: { title: string; isVisible?: boolean };
  description: { description?: string; isVisible?: boolean } | undefined;
}) {
  return (
    <div
      id="tableCaption"
      className={`${
        !title.isVisible && !description?.isVisible && 'hidden'
      } rounded-t border border-b-0 border-slate-300 bg-slate-100 px-6 py-4 text-left text-gray-700`}
    >
      <span className="md:text-display-4 text-lg font-medium">{title.title}</span>
      {description?.isVisible && <p className="mt-2 text-base">{description?.description}</p>}
    </div>
  );
}

export default TableCaption;
