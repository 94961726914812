function QuoteIcon({ className, style }: { className: string; style: React.CSSProperties }) {
  return (
    <svg
      width="35"
      height="27"
      viewBox="0 0 35 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M7.34668 13.4844V14.375H13.3936V26.8438H0.924805V14.4688C0.924805 5.0625 5.08105 0.359375 13.3936 0.359375V5.60938C11.2686 5.95312 9.72168 6.76563 8.75293 8.04688C7.81543 9.29688 7.34668 11.1094 7.34668 13.4844ZM28.5811 13.4844V14.375H34.6279V26.8438H22.2061V14.4688C22.2061 5.0625 26.3467 0.359375 34.6279 0.359375V5.60938C30.5967 6.14062 28.5811 8.76562 28.5811 13.4844Z"
        fill="#E25F4D"
      />
    </svg>
  );
}

export default QuoteIcon;
