import QuoteIcon from './icons/QuoteIcon';

interface QuoteProps {
  quoteText: string;
  quotePerson?: string;
}

function Quote({ quoteText, quotePerson }: QuoteProps) {
  return (
    <figure className="text-primary relative mx-auto my-10 flex w-full max-w-2xl flex-col gap-2 px-4 pb-4 pt-6 md:my-16 md:gap-4 md:pb-8 md:pt-10 lg:px-6">
      <TopLine />
      <blockquote className="font-larken text-lg font-medium italic sm:text-2xl md:text-2xl">
        {quoteText}
      </blockquote>
      {quotePerson && <figcaption className="text-sm md:text-lg">- {quotePerson}</figcaption>}
      <BottomLine />
    </figure>
  );
}

function TopLine() {
  const percentFromLeft = '15%'; // How far the icon is positioned from the left
  const gap = '6px'; // Gap between line and icon

  // These two variables should be equal. Tailwind value and pixel
  const widthOfIcon = 'w-7'; // Size of icon
  const sizeOfIconInPx = '28px'; // Size of icon in px

  const afterLineWidth = `calc(100% - ${percentFromLeft} - ${gap} - ${sizeOfIconInPx})`;
  const afterLineLeft = `calc(${gap} + ${sizeOfIconInPx} + ${percentFromLeft})`;

  return (
    <div className="absolute left-0 top-0 w-full">
      <span className="absolute h-0.5 w-[calc(15%-6px)] bg-[#E25F4D]" />
      <QuoteIcon
        className={`absolute left-[] top-0 aspect-square ${widthOfIcon} -translate-y-1/2`}
        style={{ left: percentFromLeft }}
      />
      <span
        className={`absolute top-0 block left-[${gap}+${sizeOfIconInPx}+${percentFromLeft}] h-0.5 bg-[#E25F4D]`}
        style={{
          width: afterLineWidth,
          left: afterLineLeft,
        }}
      />
    </div>
  );
}

function BottomLine() {
  return <span className="absolute inset-x-0 bottom-0 block h-0.5 w-full bg-[#E25F4D]" />;
}

export default Quote;
