import Link from 'next/link';
import { FiArrowRight } from 'react-icons/fi';

interface Props {
  title: string;
  url: string;
}

function LinkStandalone({ title, url }: Props) {
  return (
    <Link
      href={url || '/'}
      className="bg-background my-8 flex items-center justify-between gap-8 rounded-md p-6 duration-300 hover:bg-[#ddd9d3] active:bg-[#c1bdb9] md:px-10"
    >
      <div className="flex flex-col">
        <span
          className="text-tertiary-variant mb-2 inline-block text-lg font-semibold md:text-xl"
          aria-hidden
        >
          Les også
        </span>
        <span className="md:text-xl">{title}</span>
      </div>
      <FiArrowRight className="h-5 min-h-[20px] w-5 min-w-[20px]" />
    </Link>
  );
}

export default LinkStandalone;
