function TableRow({ cells, isLastRow }: { cells: string[]; isLastRow: boolean }) {
  return (
    <tr>
      {cells.map((cell, index) => {
        const isFirst = index === 0;
        const isLast = index === cells.length - 1;
        return (
          <td
            // INFO: safe to use index, table is currently static (no sort|reordering|filter)
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`p-3 ${isFirst && 'font-medium text-gray-800'} ${!isLastRow && 'border-b'} ${
              !isLast && 'border-r'
            }`}
          >
            {cell}
          </td>
        );
      })}
    </tr>
  );
}

export default TableRow;
