/* eslint-disable react/jsx-props-no-spreading */
import type { VariantProps } from 'class-variance-authority';
import { cva, cx } from 'class-variance-authority';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const buttonVariants = cva(
  ['rounded-[32px]', 'border', 'hover:rounded-md', 'duration-300', 'font-bold'],
  {
    variants: {
      variant: {
        primary: ['bg-secondary', 'text-on-secondary', 'border-transparent'],
        secondary: ['bg-info-container', 'text-on-info-container', 'border-secondary'],
        'primary-dark': ['bg-primary', 'text-on-secondary', 'border-transparent'],
        'secondary-dark': ['bg-primary', 'text-on-primary', 'border-secondary'],
      },
      size: {
        default: 'text-base lg:text-lg lg:px-8 py-2 px-4',
        icon: 'text-base lg:text-lg',
      },
      fullwidth: {
        default: 'max-w-max',
        full: 'w-full flex justify-center',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      fullwidth: 'default',
    },
  },
);

interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, variant, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        type="button"
        className={twMerge(cx(buttonVariants({ variant, className })))}
      >
        {children}
      </button>
    );
  },
);

export default Button;
