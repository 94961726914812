import { AnimationControls, motion } from 'framer-motion';
import { Ref } from 'react';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { BsHandIndex } from 'react-icons/bs';

function ScrollHint({
  scrollHintRef,
  controls,
}: {
  scrollHintRef: Ref<HTMLDivElement> | undefined;
  controls: AnimationControls;
}) {
  return (
    <motion.div
      ref={scrollHintRef}
      animate={controls}
      className="absolute bottom-3 right-3 flex items-center justify-center rounded-lg bg-black/60 px-3 py-2 text-white backdrop-blur-sm md:hidden"
    >
      <motion.div
        className="relative mr-2"
        animate={{ x: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
      >
        <AiOutlineExpandAlt className="rotate-45 text-2xl" />
        <BsHandIndex className="absolute -bottom-1 right-0 text-xl" />
      </motion.div>
      <span className="text-sm font-medium">Scroll sideways</span>
    </motion.div>
  );
}

export default ScrollHint;
