/* eslint-disable import/no-cycle */
import { PortableTextBlock } from '@portabletext/types';
import { AnimatePresence, easeInOut, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import PortableText from '../PortableText';

export type FactType = {
  title: string;
  bodyText: PortableTextBlock[];
};

interface FactBoxProps {
  value: FactType;
}

const boxVariants = {
  open: {
    height: 'auto',
  },
  closed: {
    height: '150px',
  },
};

const buttonVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
  closed: (direction: number) => ({
    opacity: 0,
    y: `${direction * 30}`,
    transition: { duration: 0.5, ease: 'easeInOut' },
  }),
};

function FactBox({ value }: FactBoxProps) {
  const [expanded, setExpanded] = useState(false);
  const initialRender = useRef(true);

  const divRef = useRef<HTMLDivElement | null>(null);

  // INFO: toggling tabnavigation for portableText elements that is tabbable whenever component is not expanded/notExpanded
  useEffect(() => {
    const clickableElements = ['A', 'BUTTON'];
    const elementsToUpdate: HTMLElement[] = [];
    initialRender.current = false;

    function traverseAndCheck(node: Node): void {
      if (node instanceof HTMLElement && clickableElements.includes(node.tagName)) {
        elementsToUpdate.push(node);
      }

      for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i];
        if (childNode instanceof Element) {
          traverseAndCheck(childNode);
        }
      }
    }

    if (divRef.current) {
      traverseAndCheck(divRef.current);

      for (let i = 0; i < elementsToUpdate.length; i++) {
        const el = elementsToUpdate[i];
        el.tabIndex = expanded ? 0 : -1;
        if (expanded && i === 0) {
          el.focus();
        }
      }
    }
  }, [expanded]);

  return (
    <div className="relative mb-14 mt-10">
      <article className="bg-info-container text-on-info-container info-box relative overflow-hidden rounded-md p-8 md:p-12">
        <motion.div
          initial="closed"
          animate={expanded ? 'open' : 'closed'}
          transition={{
            duration: 0.3,
            delay: 0.05,
            ease: easeInOut,
          }}
          variants={boxVariants}
          className={`after:from-info-container relative overflow-hidden ${
            expanded ? '' : 'after:absolute'
          } after:inset-0 after:bg-gradient-to-t after:from-10% after:to-transparent after:to-50%`}
        >
          <h3 className="mb-4 text-xl font-semibold md:text-2xl">{value.title}</h3>
          <div ref={divRef}>
            <PortableText value={value.bodyText} />
          </div>
        </motion.div>
      </article>
      <AnimatePresence mode="wait">
        <button
          type="button"
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
          aria-expanded={expanded}
          className="absolute bottom-0 left-1/2 z-10 -translate-x-1/2 translate-y-1/2 rounded-[24px] bg-[#C6DAFF] px-8 py-2 duration-300 hover:rounded-md active:rounded-[24px]  md:text-lg "
        >
          {expanded ? (
            <motion.span
              key="open"
              initial="closed"
              animate="open"
              exit="closed"
              custom={1}
              variants={buttonVariants}
            >
              Vis mindre
            </motion.span>
          ) : (
            <motion.span
              key="closed"
              initial="closed"
              animate="open"
              exit="closed"
              custom={-1}
              variants={buttonVariants}
            >
              Vis mer
            </motion.span>
          )}
        </button>
      </AnimatePresence>
    </div>
  );
}

export default FactBox;
